import { DIMENSIONS } from '@canalplus/mycanal-commons';
import { IBackgroundParameters } from '@canalplus/mycanal-sdk';
import {
  ApiV2StrateContentButton,
  type ApiV2Context,
} from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import IconPause from '../../assets/svg/pause.svg';
import IconPlay from '../../assets/svg/play.svg';
import { ImageType } from '../ImageType/ImageType';
import { Linker as FallbackLinker } from '../Linker/Linker';
import { VideoBackground } from '../VideoBackground/VideoBackground';
import styles from './OfferHighlight.module.css';

export type OfferHighlightContent = {
  backgroundParameters?: IBackgroundParameters;
  buttons?: ApiV2StrateContentButton[];
  description?: string;
  label?: string;
  textSide?: string;
  title?: string;
  type?: string;
  URLLogoChannel?: string;
};

type LabelPlayPauseBtn = {
  playBtn: string;
  pauseBtn: string;
};

export type OfferHighlightProps = {
  contents: OfferHighlightContent[];
  isSmallScreenWidth: boolean;
  trackingContext?: ApiV2Context;
  isTvDevice?: boolean;
  isVideoDisabled?: boolean;
  labelPlayPauseBtn?: LabelPlayPauseBtn;
  Linker?:
    | React.ForwardRefExoticComponent<any>
    | ((props: any) => React.ReactElement);
};

export function OfferHighlight({
  contents,
  trackingContext,
  isSmallScreenWidth,
  isTvDevice,
  isVideoDisabled = false,
  labelPlayPauseBtn,
  Linker = FallbackLinker,
}: OfferHighlightProps): JSX.Element {
  const {
    backgroundParameters,
    buttons = [],
    description,
    title,
    URLLogoChannel,
  } = contents[0];
  const { playBtn, pauseBtn } = labelPlayPauseBtn || {};

  // Image stuff
  const URLBackgroundImage = useMemo(
    () => ({
      default: backgroundParameters?.URLImageOptimizedRegular,
      mobile: backgroundParameters?.URLImageOptimizedCompact,
    }),
    [backgroundParameters]
  );
  const imageCredit = backgroundParameters?.imageCredit;

  // Video stuff
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isVideoPlayed, setIsVideoPlayed] = useState(true);

  const videoUrl = useMemo(() => {
    const { URLVideoOptimizedCompact = '', URLVideoOptimizedRegular = '' } =
      backgroundParameters || {};
    if (
      (isSmallScreenWidth && URLVideoOptimizedCompact) ||
      !URLVideoOptimizedRegular
    ) {
      return URLVideoOptimizedCompact;
    }
    return URLVideoOptimizedRegular;
  }, [backgroundParameters, isSmallScreenWidth]);
  const videoCredit = backgroundParameters?.videoCredit;

  const showVideo = videoUrl && isImageLoaded && !isVideoDisabled;
  const noPictureOrVideo = !backgroundParameters && !showVideo;

  const handleImageLoaded = useCallback(() => {
    if (!isImageLoaded) {
      setIsImageLoaded(true);
    }
  }, [isImageLoaded]);

  return (
    <div
      className={classNames(styles.OfferHighlight, 'offerHighlightFocus', {
        [styles.OfferHighlight__noPictureOrVideo]: noPictureOrVideo,
      })}
    >
      {backgroundParameters && (
        <ImageType
          className={styles.OfferHighlight__image}
          dimensions={DIMENSIONS.OFFER_HIGHLIGHT.image}
          URLImage={URLBackgroundImage}
          onLoad={handleImageLoaded}
          isTvDevice={isTvDevice}
          dataTestId="offerHighlight-backgroundImage"
          isArtDirection
        />
      )}

      {showVideo && (
        <VideoBackground
          videoUrl={videoUrl}
          isVideoPlayed={isVideoPlayed}
          isBottomCropped
        />
      )}

      <div
        className={classNames(styles.OfferHighlight__layoutWrap, {
          [styles.OfferHighlight__layoutWrap__noPictureOrVideo]:
            noPictureOrVideo,
        })}
      >
        <div className={styles.OfferHighlight__contentWrap}>
          {isTvDevice && (
            <ImageType
              className={styles.OfferHighlight__logo}
              dimensions={DIMENSIONS.OFFER_HIGHLIGHT.logo}
              URLImage={{ default: URLLogoChannel }}
              isTvDevice
              dataTestId="offerHighlight-logo"
            />
          )}
          {title && (
            <span className={styles.OfferHighlight__title}>{title}</span>
          )}

          {description && (
            <p className={styles.OfferHighlight__description}>{description}</p>
          )}

          <div className={styles.OfferHighlight__actionsWrap}>
            {!!buttons.length &&
              buttons.map((button) => {
                return (
                  <Linker
                    key={button?.label}
                    className={styles.OfferHighlight__button}
                    data={{ mainOnClick: button?.onClick, trackingContext }}
                    objKey="onClick"
                  >
                    {button?.label}
                  </Linker>
                );
              })}
            {imageCredit && (
              <span
                className={classNames(styles.OfferHighlight__credit, {
                  [styles['OfferHighlight__credit--hidden']]: showVideo,
                })}
              >
                {imageCredit}
              </span>
            )}
            {videoCredit && (
              <span
                className={classNames(
                  styles.OfferHighlight__credit,
                  styles['OfferHighlight__credit--hidden'],
                  {
                    [styles['OfferHighlight__credit--show']]: showVideo,
                  }
                )}
              >
                {videoCredit}
              </span>
            )}
            {!isTvDevice && (
              <button
                type="button"
                className={classNames(
                  styles.OfferHighlight__controlVideoButton,
                  {
                    [styles['OfferHighlight__controlVideoButton--show']]:
                      showVideo,
                  }
                )}
                onClick={() =>
                  setIsVideoPlayed((wasVideoPlayed) => !wasVideoPlayed)
                }
                aria-label={isVideoPlayed ? playBtn : pauseBtn}
              >
                {isVideoPlayed ? <IconPause /> : <IconPlay />}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
