import { memo } from 'react';
import styles from './ShowcaseText.css';

export type ShowcaseTextProps = {
  type: string;
  content: {
    text: string[];
  };
};

function ShowcaseText({ content, type }: ShowcaseTextProps): JSX.Element {
  return (
    <div className={styles.showcaseText}>
      <p className={styles[`showcaseText__${type}`]}>{content.text[0]}</p>
    </div>
  );
}

export default memo(ShowcaseText);
