import { DiveIconPosition } from '../../types/Dive.types';
import type { DiLinkProps } from '../internal/DiLink/DiLink.types';

export enum LinkSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

/**
 * Props for the Link component.
 */
export type LinkProps = {
  /**
   * Size of the link.
   *
   * @default "medium"
   */
  size?: `${LinkSize}`;
  /**
   * Whether the link is used as an icon.
   *
   * @default false
   */
  hasIcon?: boolean;
  /**
   * Whether the link color styles are reversed.
   *
   * @default false
   */
  isReversed?: boolean;
  /**
   * Position of the `chevron` icon.
   *
   * See `hasIcon` prop on how to enable the icon display.
   *
   * @defaultValue 'right'
   */
  iconPosition?: `${DiveIconPosition}`;
} & DiLinkProps;
