import { useCallback, useEffect, useState } from 'react';

export interface UseIntersectionObserverOptions
  extends IntersectionObserverInit {
  isEnabled?: boolean;
  onIntersect?: () => void;
  onIntersectEntry?: (entry: IntersectionObserverEntry) => void;
}

/**
 * Custom hook based on the IntersectionObserver API.
 *
 * @param options Options object
 * @param options.isEnabled When set to `false`, prevents `targetRef` from being observed
 * @param options.onIntersect Callback function to be triggered when `targetRef` intersects with the viewport (`root`)
 * @param options.root Bounding box of the viewport for `targetRef`, defaults to document viewport's bounds
 * @param options.threshold A number or array of numbers between 0 and 1 representing percentages of the target element which are visible and at which the observer will report changes
 * @param options.rootMargin CSS-like margin around `root`, with syntax similar to CSS `margin` property, e.g.: `"10px 20px 30px 40px"`
 * @returns Object { entry: `IntersectionObserverEntry`, refCallback: (node: Element | null) => void}
 * use refCallback to get `ref` of React element to cover usecases with changing ref
 * @example
 *    const { refCallback } = useIntersectionObserver();
 *    return <div ref={refCallback}><p>Test</p></div>
 * @link https://react.dev/reference/react-dom/components/common#ref-callback
 * @link https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
 */
export const useIntersectionObserver = ({
  threshold = 0,
  root = null,
  rootMargin = '0%',
  isEnabled = true,
  onIntersect,
  onIntersectEntry,
}: UseIntersectionObserverOptions): {
  entry: IntersectionObserverEntry | undefined;
  refCallback: (node: Element | null) => void;
} => {
  const [target, setTarget] = useState<Element | null>(null);
  const [entry, setEntry] = useState<IntersectionObserverEntry>();
  const isEntryIntersecting = entry?.isIntersecting;

  /**
   * Handle onIntersect callback
   */
  useEffect(() => {
    if (isEntryIntersecting) {
      onIntersect?.();
    }
  }, [isEntryIntersecting, onIntersect]);

  /**
   * Handle Observer initialization
   */
  useEffect(() => {
    const hasBrowserIoSupport = !!window.IntersectionObserver;

    if (!isEnabled || !hasBrowserIoSupport || !target) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]): void => {
        setEntry(entries[0]);
        if (onIntersectEntry) {
          entries.forEach((ientry) => onIntersectEntry(ientry));
        }
      },
      // Intersection Observer options
      { threshold, root, rootMargin }
    );

    observer.observe(target);

    // Unobserve DOM element on cleanup
    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(threshold), root, rootMargin, isEnabled, target]);

  const refCallback = useCallback((node: Element | null) => {
    setTarget(node);
  }, []);

  return { entry, refCallback };
};
