import { DIMENSIONS } from '@canalplus/mycanal-commons';
import classNames from 'classnames';
import { ImageType } from '../../ImageType/ImageType';
import { Poster } from '../../Poster/Poster';
import { PosterPlaceholder } from '../../Poster/PosterPlaceholder';
import styles from './ImageWallContent.module.css';

export type ImageWallContentProps = {
  imageAlt?: string;
  imageUrl?: string;
  isTvDevice?: boolean;
  logoAlt?: string;
  logoUrl?: string;
  ratio: string;
  title?: string;
  dataTestId?: string;
};

export function ImageWallContent({
  imageAlt,
  imageUrl,
  isTvDevice = false,
  logoAlt,
  logoUrl,
  ratio,
  title,
  dataTestId,
}: ImageWallContentProps): JSX.Element {
  return (
    <div
      className={classNames(styles.ImageWallContent, {
        [styles['ImageWallContent--shadow']]: !!title,
      })}
      data-testid={dataTestId}
    >
      {imageUrl ? (
        <Poster
          altImage={imageAlt}
          image={{ default: imageUrl }}
          dimensions={DIMENSIONS.SHOWCASE_IMAGE_WALL[ratio]}
          loading="lazy"
          isTvDevice={isTvDevice}
        />
      ) : (
        <PosterPlaceholder />
      )}
      {(title || logoUrl) && (
        <div className={styles.ImageWallContent__contentWrap}>
          {logoUrl && (
            <ImageType
              altImage={logoAlt || title}
              className={styles.ImageWallContent__logo}
              dimensions={DIMENSIONS.LOGO_CHANNEL.large}
              URLImage={{ default: logoUrl }}
              isTvDevice={isTvDevice}
            />
          )}
          {title && (
            <span className={styles.ImageWallContent__title}>{title}</span>
          )}
        </div>
      )}
    </div>
  );
}
