import { Ratio } from '@canalplus/mycanal-commons';
import { IImageWallContent } from '@canalplus/mycanal-sdk';

export const IMAGE_WALL_RATIOS = [Ratio.Ratio169, Ratio.Ratio34];

// ImageWall requires 4 contents for both 16:9 & 3:4 aspect rations, so 8 contents total
export const IMAGE_WALL_CARDS_PER_RATIO = 4;

export const formatImageWallContentsByRatio = (
  contents: IImageWallContent[],
  ratio: string
): IImageWallContent[] => {
  const newContents = contents
    .filter((content) => content.imageRatio === ratio) // limit contents to desired ratio
    .slice(0, IMAGE_WALL_CARDS_PER_RATIO); // Limit the number of contents

  // Add dummy contents if list is not long enough
  if (newContents.length < IMAGE_WALL_CARDS_PER_RATIO) {
    const numberOfPlaceholder = IMAGE_WALL_CARDS_PER_RATIO - newContents.length;
    for (let i = 1; i <= numberOfPlaceholder; i += 1) {
      newContents.push({
        contentID: `dummy-content-${newContents.length + i}-for-ratio-${ratio}`,
        imageRatio: ratio,
      });
    }
  }

  return newContents;
};

/**
 * formatImageWallContents
 * --------------------
 *
 * Formats Image Wall contents:
 * 1) Limits contents to 8 cards: 4x 16:9 and 4x 3:4 aspect ratios
 * 2) Adds dummy contents for each aspect ratio if less than 4 are provided
 *
 * @param allContents         array of images with 16:9 & 3:4 aspect ratios
 * @return formattedContents  array of formatted contents
 *
 * @category Function
 */
export const formatImageWallContents = (
  allContents: IImageWallContent[]
): IImageWallContent[] => [
  ...formatImageWallContentsByRatio(allContents, Ratio.Ratio169),
  ...formatImageWallContentsByRatio(allContents, Ratio.Ratio34),
];
