import { ApiV2OnClick } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import { memo } from 'react';
import { ButtonLayerTypes } from '../../../constants/showcase';
import ButtonInline, {
  ButtonInlineContent,
} from '../ButtonInline/ButtonInline';
import SubscribeCTA, {
  SubscribeCTAContent,
} from '../SubscribeCTA/SubscribeCTA';

export type ButtonLayerContent = {
  onClick: ApiV2OnClick;
  description?: string[];
  resources: string[];
  text: string[] | string;
  type: string;
  style: string;
};

export type ButtonLayerProps = {
  contents: (ButtonLayerContent | ButtonInlineContent | SubscribeCTAContent)[];
  id: string;
};

function ButtonLayer({ contents, id }: ButtonLayerProps): JSX.Element {
  return (
    <>
      {contents.map((content) => {
        const displayName = content.onClick.displayName;
        switch (content.type) {
          case ButtonLayerTypes.INLINE:
            return (
              <ButtonInline
                content={content as ButtonInlineContent}
                id={id}
                key={id}
              />
            );

          case ButtonLayerTypes.PLAIN:
            return (
              <SubscribeCTA
                content={content as SubscribeCTAContent}
                key={displayName}
                id={id}
              />
            );

          case ButtonLayerTypes.PRIMARY:
          default:
            return null;
        }
      })}
    </>
  );
}
export default memo(ButtonLayer);
