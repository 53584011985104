import classNames from 'classnames';
import { MaterialChevronRightSvg } from '../../icons/icons';
import { DiveIconPosition } from '../../types/Dive.types';
import { DiLink } from '../internal/DiLink/DiLink';
import { linkCVA, linkIconCVA } from './Link.cva';
import { LinkProps, LinkSize } from './Link.types';

/**
 * A Link component that renders an anchor tag with predefined styles.
 * It supports hover, active, and disabled states for better user interaction.
 *
 * @example
 * <Link href="https://example.com">Visit Example</Link>
 */
export function Link({
  children,
  size = LinkSize.Large,
  hasIcon = false,
  disabled = false,
  isReversed = false,
  iconPosition = DiveIconPosition.Right,
  className,
  ...props
}: LinkProps): JSX.Element {
  return (
    <DiLink
      className={classNames(
        linkCVA({
          isReversed,
          disabled,
          hasIcon,
          iconPosition,
          size,
        }),
        className
      )}
      disabled={disabled}
      aria-label={typeof children === 'string' ? children : undefined}
      {...props}
    >
      <>
        {children}
        {hasIcon && (
          <MaterialChevronRightSvg
            aria-hidden="true"
            className={linkIconCVA({
              disabled,
              isReversed,
              size,
            })}
          />
        )}
      </>
    </DiLink>
  );
}
