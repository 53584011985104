import { MediaImage } from '@canalplus/dive';
import {
  ApiV2StrateContentButton,
  type ApiV2Context,
} from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import classNames from 'classnames';
import { useMemo } from 'react';
import { Linker as FallbackLinker } from '../Linker/Linker';
import styles from './ReboundProduct.module.css';

export type ReboundProductProps = {
  button?: ApiV2StrateContentButton;
  title?: string;
  urlLogo?: string;
  altLogo?: string;
  trackingContext?: ApiV2Context;
  Linker?:
    | React.ForwardRefExoticComponent<any>
    | ((props: any) => React.ReactElement);
};

export function ReboundProduct({
  button,
  title,
  urlLogo,
  altLogo,
  trackingContext,
  Linker = FallbackLinker,
}: ReboundProductProps): JSX.Element {
  const buttonData = useMemo(() => ({ onClick: button?.onClick }), [button]);
  return (
    <div
      className={classNames(styles.ReboundProduct, 'reboundProductFocus')}
      data-testid="reboundproduct-container"
    >
      {urlLogo && (
        <MediaImage
          className={styles.ReboundProduct__logo}
          src={urlLogo}
          alt={altLogo}
          height="auto"
          width="3.3rem"
        />
      )}
      {title && <span className={styles.ReboundProduct__title}>{title}</span>}
      {button && (
        <Linker
          className={styles.ReboundProduct__button}
          data={{ mainOnClick: buttonData.onClick, trackingContext }}
          objKey="onClick"
        >
          {button.label}
        </Linker>
      )}
    </div>
  );
}
