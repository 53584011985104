import classNames from 'classnames';
import { memo } from 'react';
import styles from './CatchPhrase.css';

export type CatchPhraseResources = {
  URLLogo: string;
  type: string;
};

export type CatchPhraseProps = {
  content: {
    resources: CatchPhraseResources[];
    text: string[];
    type: string;
  };
};

function CatchPhrase({ content }: CatchPhraseProps): JSX.Element {
  return (
    <div className={classNames(styles.catchPhrase)}>
      {content.text.map((text) => (
        <h2 className={classNames(styles.catchPhrase__text)} key={text}>
          {text.replace('{resources[0]}', '')}
          {content.resources && (
            <img
              src={content.resources[0].URLLogo}
              className={classNames(styles.catchPhrase__logo)}
            />
          )}
        </h2>
      ))}
    </div>
  );
}

export default memo(CatchPhrase);
