import { Ratio } from '@canalplus/mycanal-commons';
import './ratioClassname.css';

/**
 * Get a className who will apply the correct css aspect-ratio depending\
 * on the `Ratio` provided
 */
export const getRatioClassname = (ratio: Ratio): string =>
  ({
    [Ratio.Square]: 'ratio--11',
    [Ratio.Ratio166]: 'ratio--166',
    [Ratio.Ratio169]: 'ratio--169',
    [Ratio.Ratio23]: 'ratio--23',
    [Ratio.Ratio34]: 'ratio--34',
    [Ratio.Ratio43]: 'ratio--43',
    [Ratio.Ratio47]: 'ratio--47',
  })[ratio];
